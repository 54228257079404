<template>
  <div class="collectable" :id="'collectable-' + collectable.id">
    <div style='position: relative'>
      <!-- <div class='price-cut' v-if="discount">-{{discount}}%</div> -->
      <template v-if="!isBox">
        <vue-load-image :class="cardClass" v-if="!carousel || loadImage">
          <img :class="cardClass" v-on:load="setupImageZoom" slot="image" :src="collectable.small_image_url" :data-original="collectable.original_image_url"/>
          <img slot="preloader" src="/images/image-loader.gif" />
          <div slot="error">error message</div>
        </vue-load-image>
      </template>
      <a v-if="isBox" :class="'vue-load-image ' + cardClass" :href="'/collectable_versions/' + collectable.id">
        <img :src="collectable.small_image_url" />
      </a>
    </div>
    <a :href="'/collectable_versions/' + collectable.id" class="text-2-lines name" :title="displayNameWithVersion">
      {{ displayName }}
      <span class="version" v-if="collectable.version != 'regular'">{{ translateVersion }}</span>
    </a>
    <p v-if="user_signed_in" class="details">{{ details }}</p>
    <p v-if="user_signed_in" class="details">ID: {{ collectable.id }}</p>
    <template v-if="!user_signed_in">
      <p class="price" v-if="!collectable.old_price"><span>Cena: {{ collectable.price }}zł</span></p>
      <!-- <p class="price" v-if="!collectable.old_price"><span>Cena: {{ collectable.price }}zł</span> | Stan: {{ collectable.quantity_for_trade }}</span> -->

      <p class="price" v-if="collectable.old_price"><span style="text-decoration:line-through">cena: {{ collectable.old_price }}zł</span></p>
      <!-- <p class="price" v-if="collectable.old_price"><span style="text-decoration:line-through">cena: {{ collectable.old_price }}zł</span> | stan: {{ collectable.quantity_for_trade }}</p> -->
      <p class="price discount" v-if="collectable.old_price">Promocyjna cena: {{ collectable.price }}zł</p>
      <p class="price" v-if="!collectable.old_price">&nbsp;</p>

      <div class="cart">
        <input class="quantity-to-cart" type="number" min="1" v-model="quantity_to_cart" v-bind:max="collectable.quantity_for_trade" />
        <button class="button add-to-cart" v-on:click="addToCart" title="Dodaj do koszyka">
          <img class="cart-icon" src="/images/shopping_trolley_plus.svg" />
        </button>
      </div>
    </template>

    <!-- Admin -->
    <template v-if="user_signed_in">
      <div v-if="batch" class='batch'>
        <span>W partii:</span>
        <input type="number" min="0" v-model="collectable.batch_quantity" v-on:change="updateBatch" />
      </div>


      <!-- <img  class="icon" src="/images/collection.png" /> -->
      <span title="Kolekcja">K</span>
      <input type="number" min="0" v-model="collectable.quantity" v-on:change="update" title="Kolekcja" />
      <a class="arrow" v-on:click="moveToCollection" title="Przenieś 1 kartę do kolekcji">⇦</a>
      <a class="arrow" v-on:click="moveToTrade" title="Przenieś 1 kartę do sklepu">⇨</a>
      <input type="number" min="0" v-model="collectable.quantity_for_trade" v-on:change="update" title="Sklep" />
      <span title="Sklep">S</span>
      <!-- <img class="icon" src="/images/trade.png" /> -->
      <div class="price">
        <input type="number" min="0" v-model="collectable.price" step="0.1" v-on:change="update" /> zł
        | T&T: <b>${{ collectable.tat_price }}</b>
      </div>
    </template>
  </div>
</template>

<script>
import VueLoadImage from 'vue-load-image'
export default {
  props: {
    collectable: {},
    user_signed_in: {},
    collection_set_permalink: {},
    carousel: false,
    index: null,
    batch: false
  },
  data() {
    return {
      quantity_to_cart: 1,
      zoom_set: false,
      loadImage: false, // blocking image rendering for carousel
    }
  },
  mounted() {
    $(this.$el).find('input').focus(function() { $(this).select(); } );
  },
  methods: {
    setupImageZoom: function() {
      if(!this.zoom_set && !this.carousel) {
        new Zooming({
          bgOpacity: 0.5,
          onImageLoading: function() {
            console.log('loading')
            $('#image-zooming-loading').removeClass('hidden')
            // setTimeout(function() {
            //   $('#image-zooming-loading').removeClass('hidden')
            // }, 200)
          },
          onImageLoaded: function() {
            console.log('loaded')
            $('#image-zooming-loading').addClass('hidden')
          },
          onBeforeClose: function() {
            console.log('beforeclose')
            $('#image-zooming-loading').addClass('hidden')
          }
        }).listen("#collectable-" + this.collectable.id + " .vue-load-image img.card, #collectable-" + this.collectable.id + " .vue-load-image img.accessory");
        this.zoom_set = true;
      }
    },
    moveToCollection: function() {
      if(this.collectable.quantity_for_trade == 0) { return }
      this.collectable.quantity += 1
      this.collectable.quantity_for_trade -= 1
      this.update()
    },
    moveToTrade: function() {
      if(this.collectable.quantity == 0) { return }
      this.collectable.quantity -= 1
      this.collectable.quantity_for_trade += 1
      this.update()
    },
    update: function() {
      this.$http.put('/api/collectable_versions/'+this.collectable.id, {
        quantity: this.collectable.quantity,
        quantity_for_trade: this.collectable.quantity_for_trade,
        price: this.collectable.price
      })
    },
    updateBatch: function() {
      this.$http.put('/api/batch_collectable_versions/'+this.collectable.id, {
        quantity: this.collectable.batch_quantity,
      })
    },
    addToCart: function() {
      if(this.quantity_to_cart == 0) { return }
      this.$http.post('/api/orders', {
        collectable_version_id: this.collectable.id,
        quantity: parseInt(this.quantity_to_cart)
      }).then(response => {
        this.$root.$emit('updateCart', response.body, this.collectable.name)
        var remainingQuantity = this.collectable.quantity_for_trade - response.body.item_quantity
        if(this.quantity_to_cart > remainingQuantity) {
          this.quantity_to_cart = remainingQuantity
        }
        if(response.body.error) { this.displayMessage(response.body, response.body.error) }
      });
    },
    displayMessage: function(cart, text) {
      $('.cart-alert').remove()
      var message = $("<div class='cart-alert' style='display:none'><a href='/orders/current'><p>"+text+"</p><p>Stan zamówienia: "+cart.price.toFixed(2)+"zł</p></a></div>")
      $('body').prepend(message)
      message.fadeIn(500, function() {
        setTimeout(function() { message.fadeOut(500); }, 5000)
      });
    }
  },
  computed: {
    cardClass: function() {
      if(this.collection_set_permalink == 'coins') {
        return 'coin'
      } else if (this.collection_set_permalink == 'accessories' || this.collection_set_permalink == 'albums') {
        return 'accessory'
      } else {
        return 'card'
      }
    },
    isBox: function() {
      return this.collection_set_permalink == 'boxes'
    },
    displayName: function() {
      var name = this.collectable.name
      if(this.collectable.collection_set_abbr) {
        name = name + ' ' + this.collectable.collection_set_abbr
      }
      return name
    },
    displayNameWithVersion: function() {
      var name = this.displayName
      if(this.collectable.version != 'regular') {
        name = name + ' ' + this.collectable.version
      }
      return name
    },
    details: function() {
      return this.collectable.number + (this.collectable.rarity ? ", " + this.collectable.rarity : '') + (this.collectable.version == 'regular' ? '' : ", " + this.collectable.version)
    },
    discount: function() {
      if(!this.collectable.old_price) { return }
      return Math.round(100*(this.collectable.old_price-this.collectable.price)/this.collectable.old_price)
    },
    translateVersion: function() {
      const version = this.collectable.version
      if(version == 'regular') { return '' }
      if(version == 'reverse') { return 'Rewers' }
      if(version == 'pokeball_reverse') { return 'Rewers Poké Ball' }
      if(version == 'masterball_reverse') { return 'Rewers Master Ball' }
    },
  },
  components: {
    'vue-load-image': VueLoadImage
  }
}
</script>

<style scoped>
div.collectable {
  font-size: 2em;
  text-align: center;
  display: inline-block;
  border-width: 2px;
  border-color: #e8e8e8;
  border-style: dashed;
  padding: 5px;
  color: white;
  background-color: #151515;
}
.details {
  white-space: nowrap;
}
span {
  font-size: 12px;
}
.name {
  font-weight: bolder;
  height: 38px;
/*  height: 19px;
  overflow: hidden;
*/  margin-top: 5px;
}
.version {
  font-weight: normal;
}
.name, .details {
  width: 213px;
  font-size: 13px;
}

.vue-load-image.card {
  height: 300px;
}
.vue-load-image.accessory {
  height: 300px;
  position: relative;
}
.vue-load-image.coin {
  height: 213px;
}

.vue-load-image img {
  width: 213px;
  object-fit: contain;
}
.vue-load-image.card img {
  border-radius: 8px;
  height: 300px;
}
.vue-load-image.coin img {
  border-radius: 110px;
  height: 213px;
}
.vue-load-image.accessory img {
  border-radius: 8px;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0%;
}

input {
  width: 40px;
  text-align: center;
  font-size: 15px;
  background-color: white;
  padding: 0;
  margin: 0;
}
input.quantity-to-cart {
  width: calc(50% - 4px);
  height: 23px;
  display: block;
  float: left;
  padding: 5px 0;
}
.add-to-cart {
  width: calc(50% - 3px);
  height: 37px;
  border-radius: 0;
  display: block;
  padding: 0;
  cursor: pointer;
  padding: 5px 0;
}
.add-to-cart img {
  height: 20px;
}
.cart {
  margin: 0 auto;
  padding-top: 5px;
  width: 100%;
}
p {
  margin: 0;
}
.icon {
  height: 20px;
}
.arrow {
  cursor: pointer;
}
.price input {
  width: 60px;
}
.price {
  font-size: 12px;
}
.discount {
  background: linear-gradient(0deg, #a21414, #e01c1c);
  color: white;
  text-shadow: 1px 1px black;
  border-radius: 10px;
  border: 1px solid #823939;
}

.price-cut {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(0deg, #a21414, #e01c1c);
  padding: 2px 5px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
  text-shadow: 1px 1px black;
  border: 1px solid #823939;
  z-index: 1;
  font-size: 26px;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: #bababa;
}
</style>
